// @flow
import { useFela, FelaComponent, } from 'react-fela';
import * as React from 'react';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import IconError from '../Icon/icons/IconError';
import Tooltip from '../Tooltip/Tooltip';

TeaserLabelDisclaimer.defaultProps = {
  companyName: '',
  miscStyles: null,
};

type Props = {
  companyName?: string,
  miscStyles?: ?StyleProps,
}
export default function TeaserLabelDisclaimer({ companyName, miscStyles, }: Props) {
  const { theme, css, } = useFela();
  const tooltipText = `${theme.labelsI18n.cooperateWithTooltip(companyName)}.\r\n ${theme.labelsI18n.tooltipInfo}`;


  return (
    <div className={css({
      color: theme.color('commercial'),
      display: 'flex',
      alignItems: 'center',
      gridArea: 'label',
      extend: [
        theme.type(-2, { lines: 2, untilBp: 'l', }),
        theme.type(-3, { lines: 2, fromBp: 'l', }),
        ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
      ],
    })}
    >
      {companyName ? (
        <Tooltip
          text={tooltipText}
          openSide="top"
          openOnMouseOver
          isClickDriven
          miscStyles={{
            display: 'inline-block',
            whiteSpace: 'break-spaces',
          }}
          tooltipMiscStyles={{
            backgroundColor: theme.color('neutral', '-2'),
            ...theme.type(-3, { lines: 2, fromBp: 'l', }),
          }}
        >
          <FelaComponent
            style={{
              display: 'flex',
            }}
            as="span"
          >
            <IconError size={2} fill={[ 'commercial', 'base', ]} miscStyles={{ marginEnd: '0.5rem', }} />
            {theme.labelsI18n.cooperateWith(companyName)}
          </FelaComponent>
        </Tooltip>
      ) : (
        <div>
          {' '}
          {theme.labelsI18n.advertorialContent}
        </div>
      )}
    </div>
  );
}
