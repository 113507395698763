// @flow
import useSlotData from './useSlotData';

type headerNewsDataKind = 'topNews' | 'breakingNews';


function getHeaderNewsFromSlot(slot: any[], kind: headerNewsDataKind) {
  return slot.find(el => el.inputTemplate === 'LiveBlogBreakingNews' || (el.inputTemplate === 'HeaderNewsList' && el.kind === kind));
}

export default function useHeaderNewsData(kind: headerNewsDataKind) {
  const mainData = useSlotData('main');
  const postHeaderData = useSlotData('postHeader');
  const main = mainData || [];
  const postHeader = postHeaderData || [];
  let headerNewsData = getHeaderNewsFromSlot(main, kind);

  if (!headerNewsData) {
    headerNewsData = getHeaderNewsFromSlot(postHeader, kind);
  }

  return headerNewsData;
}
