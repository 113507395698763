// @flow
import * as React from 'react';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import { useFela, } from 'react-fela';
import H from '../AutoLevels/H';


type AudioArticleBodyHeaderProps = {
  contentName: string,
  caption: ?string,
  exclusiveColor: ?string,
  titleColor: ?string,
  miscStyles: ?StyleProps,
};

AudioArticleBodyHeader.defaultProps = {
  contentName: null,
  caption: null,
  exclusiveColor: null,
  titleColor: null,
  miscStyles: null,
};

function AudioArticleBodyHeader({
  contentName,
  caption,
  exclusiveColor,
  titleColor,
  miscStyles,
}: AudioArticleBodyHeaderProps) {
  const { css, theme, } = useFela();
  const titleParts = (caption || contentName || '').split('|');
  const exclusive = titleParts.length > 1 ? titleParts.shift().trim() : null;
  const title = titleParts.join('|').trim();

  return (
    <div
      className={css({
        gridArea: 'he',
        color: theme.color('audioPlayerArticleBody', 'header'),
        userSelect: 'text',
        extend: [
          theme.mq({ until: 's', }, { marginBottom: '2rem', }),
          theme.mq({ from: 's', }, { marginBottom: '-1.5rem', }),
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      {exclusive && (
        <span
          className={css({
            fontWeight: theme.audioPlayerI18n.exclusiveFontWeight,
            color: exclusiveColor,
            ':after': {
              content: '"\\00a0|\\00a0"',
            },
          })}
        >
          {exclusive}
        </span>
      )}

      <H className={css({
        color: titleColor,
        fontWeight: '700',
        display: 'inline', })}
      >
        {title}
      </H>
    </div>
  );
}

export default AudioArticleBodyHeader;
