// @flow
import * as React from 'react';
import { FelaComponent, } from 'react-fela';

import H from '../AutoLevels/H';
import HtzLink from '../HtzLink/HtzLink';
import Time from '../Time/Time';
import { useEventTracker, } from '../../utils/EventTracker';

export type BreakingNewsItemType = {
  title: string,
  // creationDateTime: number,
  date: number,
  // eslint-disable-next-line react/no-unused-prop-types
  url: ?string,
};

type BreakingNewsItemProps = BreakingNewsItemType & {
  isVisible: boolean,
  animationDuration: number,
  contentName?: string,
};

BreakingNewsItem.defaultProps = {
  url: null,
  contentName: null,
};

export default function BreakingNewsItem({
  isVisible,
  animationDuration,
  title,
  date,
  url,
  contentName,
}: BreakingNewsItemProps): React.Node {
  const { biAction, } = useEventTracker();
  return (
    <FelaComponent
      as="li"
      style={({ theme, }) => ({
        position: 'absolute',
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? undefined : 'none',
        zIndex: isVisible ? 1 : 0,
        transitionProperty: 'opacity',
        ...theme.getDuration('transition', animationDuration),
        ...theme.getTimingFunction('transition', 'swiftOut'),
      })}
    >
      <FelaComponent style={({ theme, }) => ({ ...theme.type(-1, { lines: 3, }), })} as="article">
        {url ? (
          <HtzLink
            href={url}
            onClick={() => biAction({
              actionCode: 109,
              featureType: 'Content',
              feature: 'HP Breaking News',
            })}
          >
            <BreakingNewsContent title={contentName || title} creationDateTime={date} />
          </HtzLink>
        ) : (
          <BreakingNewsContent title={contentName || title} creationDateTime={date} />
        )}
      </FelaComponent>
    </FelaComponent>
  );
}

/* ****************************************
  Breaking news content component
 ****************************************** */
type BreakingNewsContentType = {
  title: string,
  creationDateTime: number,
};
function BreakingNewsContent({ title, creationDateTime, }: BreakingNewsContentType): React.Node {
  return (
    <React.Fragment>
      <FelaComponent
        style={{
          fontWeight: 700,
          marginInlineEnd: '0.5rem',
        }}
      >
        {({ className, theme, }) => <Time time={creationDateTime} format={theme.pageDateTime && theme.pageDateTime.timeFormat ? theme.pageDateTime.timeFormat : 'HH:mm'} className={className} />}
      </FelaComponent>
      <span>&#32;</span>
      <FelaComponent style={{ display: 'inline', fontWeight: 400, }}>
        {({ className, }) => <H className={className}>{title}</H>}
      </FelaComponent>
    </React.Fragment>
  );
}
