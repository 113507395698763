/* global document */
import React, { Fragment, useState, useEffect, useRef, useCallback, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseComponentProp, rgba, } from '@haaretz/htz-css-tools';

import ToolBar from './elements/ToolBar';
import IconArrow from '../Icon/icons/IconArrow';

const propTypes = {
  captionElement: PropTypes.node,
  /**
   * The name of the shown item.
   */
  itemName: PropTypes.string.isRequired,
  /**
   * The path to the of the shown item.
   */
  itemUrl: PropTypes.string.isRequired,
  /**
   * A render prop that passes down `isFullScreen` (Boolean).
   */
  render: PropTypes.func.isRequired,
  /**
   * Should the media be displayed as full-screen only.
   */
  fullScreenOnly: PropTypes.bool,
  /**
   * Should the full-screen option be disabled.
   */
  disableFullScreen: PropTypes.bool,
  exitAction: PropTypes.func,
};

const defaultProps = {
  captionElement: null,
  fullScreenOnly: false,
  disableFullScreen: false,
  exitAction: null,
};

const containerStyle = ({ isFullScreen, disableFullScreen, theme, }) => ({
  overflow: 'hidden',
  position: 'relative',
  ...(isFullScreen
    ? {
      backgroundColor: theme.color('neutral'),
      display: 'flex',
      height: '100%',
      position: 'fixed',
      top: '0',
      start: '0',
      end: '0',
      bottom: '0',
      width: '100%',
      zIndex: theme.getZIndex('modal'),
      extend: [
        parseComponentProp(
          'flexDirection',
          [
            { until: 's', misc: 'portrait', value: 'column', },
            { from: 's', misc: 'portrait', value: 'row', },
            { until: 'm', misc: 'landscape', value: 'column', },
            { from: 'm', misc: 'landscape', value: 'row', },
          ],
          theme.mq,
          (prop, value) => ({ [prop]: value, })
        ),
      ],
    }
    : !disableFullScreen
      ? { cursor: 'zoom-in', }
      : {}),
});

const iconStyle = ({ theme, }) => ({
  backgroundColor: theme.color('neutral', 'base', 0.8),
  height: '5rem',
  marginBottom: '3rem',
  padding: '1rem',
  opacity: '1',
  width: '100%',
  zIndex: '6',
});

// eslint-disable-next-line react/prop-types
const Icon = props => {
  const { css, theme, } = useFela();
  return (
    <button
      type="button"
      className={css(iconStyle)}
      {...props}
      aria-label={theme.zoomoutText}
      tabIndex="-1"
    >
      <IconArrow
        color={[ 'neutral', '-10', ]}
        size={3}
        miscStyles={{
          display: 'block',
          margin: '0 auto',
          ...(theme.direction === 'ltr'
            ? {
              transform: 'rotateY(180deg)',
            }
            : {}),
        }}
      />
    </button>
  );
};

const mediaWrapperStyle = ({ isFullScreen, theme, }) => (isFullScreen
  ? {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    extend: [
      theme.mq(
        { from: 's', },
        {
          display: 'flex',
          flexDirection: 'column',
        }
      ),
    ],
  }
  : {});

/**
 * This component receives a media component
 * ([Image](./#image), [Video](./#video), [Gallery](./#gallery), etc),
 * and displays it in a full-screen, with it's caption & credit, and some sharing buttons.
 */
function FullScreenMedia({
  fullScreenOnly,
  exitAction,
  render,
  captionElement,
  itemName,
  itemUrl,
  disableFullScreen,
}) {
  const mediaRef = useRef();
  // const [ mediaWidth, setMediaWidth, ] = useState(mediaRef.current.offsetWidth);
  const [ isFullScreen, setIsFullScreen, ] = useState(fullScreenOnly);
  const { css, } = useFela({ isFullScreen, disableFullScreen, });

  const toggleFullScreen = useCallback(() => {
    if (!exitAction || !isFullScreen) {
      setIsFullScreen(!isFullScreen);
    }
    else {
      document.getElementsByTagName('html')[0].style.overflow = 'visible';
      exitAction();
    }
  }, [ exitAction, isFullScreen, ]);

  const handleGlobalKeydown = useCallback(
    e => {
      const key = e.which || e.keyCode;
      if (key === 27) {
        toggleFullScreen();
      }
    },
    [ toggleFullScreen, ]
  );

  useEffect(() => {
    if (isFullScreen) {
      document.addEventListener('keydown', handleGlobalKeydown);
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
    else {
      document.removeEventListener('keydown', handleGlobalKeydown);
      document.getElementsByTagName('html')[0].style.overflow = 'visible';
    }
  }, [ handleGlobalKeydown, isFullScreen, ]);

  return (
    <div className={css(containerStyle)}>
      <div />

      <div ref={mediaRef} className={css(mediaWrapperStyle)}>
        {render({ isFullScreen, toggleFullScreen, })}
      </div>

      {isFullScreen ? (
        <Fragment>
          <div
            className={css({
              position: 'absolute',
              top: '2rem',
              end: '2rem',
              zIndex: 5,
            })}
          >
            <Icon onClick={toggleFullScreen} />
          </div>
          <ToolBar
            itemName={itemName}
            itemUrl={itemUrl}
            closeButton={<Icon onClick={toggleFullScreen} />}
            captionElement={captionElement}
          />
        </Fragment>
      ) : null}
    </div>
  );
}

FullScreenMedia.propTypes = propTypes;
FullScreenMedia.defaultProps = defaultProps;

export default FullScreenMedia;
