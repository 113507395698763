// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, parseComponentProp, type StyleProps, type ComponentPropResponsiveObject, } from '@haaretz/htz-css-tools';

type Props = {
  children: React.Node,
  isCenteredContent: boolean | ComponentPropResponsiveObject<boolean>[],
  miscStyles: ?StyleProps,
  tagName: string,
};

TeaserContent.defaultProps = {
  children: null,
  isCenteredContent: false,
  miscStyles: null,
  tagName: 'div',
};

function setTextAlignCenter(isCentered, value) {
  return value
    ? {
      textAlign: 'center',
    }
    : {};
}

export default function TeaserContent({
  children,
  isCenteredContent,
  miscStyles,
  tagName,
}: Props): React.Node {
  const Component = tagName;
  const { css, theme, } = useFela();
  const className = css({
    gridArea: 'content',
    extend: [
      ...(miscStyles
        ? parseStyleProps(miscStyles, theme.mq, theme.type)
        : []),
      parseComponentProp(
        'isCenteredContent',
        isCenteredContent,
        theme.mq,
        setTextAlignCenter
      ),
    ],
  });

  return <Component className={className}>{children}</Component>;
}
