// @flow
import * as React from 'react';
import gql from 'graphql-tag';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import type { ImageDataType, } from '../../flowTypes/ImageDataType';

import Query from '../ApolloBoundary/Query';

import AudioPlayerWithActions from '../AudioPlayerWithActions/AudioPlayerWithActions';
import AudioHeader from './ArticleBodyAudioHeader';
import AudioRss from './ArticleBodyAudioRss';
import {
  AudioProgressController,
  PlayButton,
  SkipButton,
  PlaybackRateButton,
  MuteButton,
  AudioElapsedTime,
  AudioDuration,
} from '../HtzAudioPlayer';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import ArticleBodyAudioWebView from './ArticleBodyAudioWebView';


const getArticleType = gql`
  query GetArticleType {
    articleType @client
  }
`;

const playerControllerWrapperStyle = ({ isHeadline, theme, }) => ({
  backgroundColor: theme.color('audioPlayerArticle', 'bg'),
  paddingStart: isHeadline ? null : '1rem',
  paddingEnd: isHeadline ? null : '1rem',
  paddingTop: '1rem',
  display: 'grid',
  gridRowGap: '1rem',
  gridTemplateColumns: '5rem auto 5rem',
  color: theme.color('audioPlayerArticle', 'color'),
  fontWeight: 'bold',
  ...theme.type(-2),
  ...theme.mq(
    { until: 's', },
    {
      gridTemplateAreas: `
"he he he"
"progress progress progress"
"duration . elapsed"
"rate controls mute"
"rss rss rss"
`,
    }
  ),
  ...theme.mq(
    { from: 's', },
    {
      gridTemplateAreas: `
"he he he"
"progress progress progress"
"duration . elapsed"
"rate controls mute"
". controls ."
"rss rss rss"
`,
    }
  ),
});

const wrapperMiscStyles = ({ theme, isArticleNarration, isHeadline, miscStyles, }) => ({
  maxWidth: isHeadline ? [ { from: 'm', value: '80%', }, ] : null,
  marginRight: 'auto',
  marginLeft: 'auto',
  borderTop: isArticleNarration || isHeadline ? null
    : [ { until: 'xl', value: [ 1, 5, 'solid', theme.color('audioPlayerArticle', 'borderColor'), ], },
      { from: 'xl', value: [ 1, 4.5, 'solid', theme.color('audioPlayerArticle', 'borderColor'), ], }, ],
  borderBottom: isArticleNarration || isHeadline ? null
    : [ { until: 'xl', value: [ 1, 5, 'solid', theme.color('audioPlayerArticle', 'borderColor'), ], },
      { from: 'xl', value: [ 1, 4.5, 'solid', theme.color('audioPlayerArticle', 'borderColor'), ], }, ],
  ...miscStyles,
});

const muteIconSize = [ { until: 'xl', value: 2.5, }, ];

const playIconSize = [
  { until: 's', value: 3, },
  { from: 's', value: 3, },
];

const playButtonDiameter = [
  { until: 's', value: '6rem', },
  { from: 's', until: 'xl', value: '7rem', },
  { from: 'xl', value: '6rem', },
];

const playButtonMiscStyles = {
  marginRight: '6rem',
  marginLeft: '6rem',
};

const pauseBarHeight = [
  { until: 's', value: '2.5rem', },
  { from: 's', until: 'xl', value: '3rem', },
  { from: 'xl', value: `${18 / 7}rem`, },
];

const pauseBarWidth = '5px';

type Props = {
  playerType?: 'default' | 'narration',
  fileUrl: string,
  contentName: string,
  channelLinks: ?{
    spotify: string,
    google: string,
    apple: string,
  },
  isOmny: boolean,
  miscStyles: ?StyleProps,
  isHeadline: boolean,
  loadAfterFirstClick: boolean,
  mobileTitle?: string,
  channelLabel?: string,
  image?: ImageDataType,
};

ArticleBodyAudio.defaultProps = {
  playerType: 'default',
  fileUrl: null,
  contentName: null,
  channelLinks: null,
  isOmny: false,
  miscStyles: null,
  isHeadline: false,
  loadAfterFirstClick: true,
  mobileTitle: null,
  channelLabel: null,
  image: null,
};

function ArticleBodyAudio(props: Props) {
  const { css, theme, } = useFela();
  const [ ref, inView, ] = useInView({ triggerOnce: true, threshold: 0.8, });
  const isWebView = useWebViewChecker();
  if (isWebView) {
    return <ArticleBodyAudioWebView {...props} />;
  }

  const {
    playerType,
    fileUrl,
    contentName,
    channelLinks,
    isOmny,
    loadAfterFirstClick,
    miscStyles,
    isHeadline, } = props;

  const isArticleNarration = playerType === 'narration';
  return (
    <Query query={getArticleType}>
      {({ data, }) => (
        <AudioPlayerWithActions
          wrapperRef={ref}
          fileUrl={fileUrl}
          contentName={contentName}
          isOmny={isOmny}
          miscStyles={wrapperMiscStyles({ theme, isArticleNarration, isHeadline, miscStyles, })}
          sendBiImpression={inView}
          articleType={(data || {}).articleType || ''}
          loadAfterFirstClick={loadAfterFirstClick}
        >
          {isArticleNarration || isHeadline ? null : (
            <AudioHeader
              contentName={contentName}
              titleColor={theme.color('audioPlayerArticle', 'titleColor')}
              exclusiveColor={theme.color('audioPlayerArticle', 'exclusiveColor')}
              miscStyles={{
                ...theme.mq({ until: 's', }, { marginBottom: '0rem', }),
              }}
            />
          )}

          <div className={css(playerControllerWrapperStyle({ isHeadline, theme, }))}>
            <AudioProgressController
              railColor={[ 'audioPlayerArticle', 'rail', ]}
            />
            <div
              className={css({
                gridArea: 'controls',
                display: 'flex',
                justifySelf: 'center',
              })}
            >
              <SkipButton skipBy={15} />
              <PlayButton
                color={[ 'audioPlayerArticle', 'playBtn', ]}
                bgColor={[ 'audioPlayerArticle', 'playBg', ]}
                diameter={playButtonDiameter}
                playIconSize={playIconSize}
                pauseBarHeight={pauseBarHeight}
                pauseBarWidth={pauseBarWidth}
                miscStyles={playButtonMiscStyles}
              />
              <SkipButton skipBy={-15} />
            </div>
            <MuteButton
              size={muteIconSize}
              miscStyles={{
                justifySelf: 'end',
              }}
            />
            <PlaybackRateButton
              miscStyles={{
                justifySelf: 'start',
                ...theme.type(-2),
              }}
            />
            <AudioElapsedTime />
            <AudioDuration />
            {channelLinks && !isArticleNarration && !isHeadline
              ? (
                <AudioRss
                  channelLinks={channelLinks}
                  miscStyles={{
                    justifySelf: 'center',
                    fontWeight: 400,
                    marginTop: [ { until: 's', value: '1rem', }, ],
                  }}
                />
              )
              : null}
          </div>
        </AudioPlayerWithActions>
      )}
    </Query>
  );
}
export default ArticleBodyAudio;
