// @flow
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import { useFela, } from 'react-fela';
import * as React from 'react';

import type { GridContainerType, } from '../../flowTypes/GridContainerType';
import ItemPlaceholder from '../ItemPlaceholder/ItemPlaceholder';
import ListView from '../ListView/NewListView';
import ListViewHeader from '../ListViewHeader/ListViewHeader';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';

export default function GridContainer({
  columns,
  items,
  miscStyles,
  title,
  ...props
}: GridContainerType): React.Node {
  const getComponent = useGetComponent();
  const { css, theme, } = useFela();
  if (!items) return null;
  const colTemplate = columns
    ? columns.map(colCount => ({
      ...colCount,
      value: `repeat(${colCount.value}, 1fr)`,
    }))
    : null;

  return (
    // Flow doesn't recognize the value has be casted into a string
    // $FlowFixMe
    <ListView colTemplate={colTemplate} miscStyles={miscStyles} sectionMiscStyles={miscStyles}>
      {title ? (
        <ListViewHeader
          isHorizontal
          disableGridArea
          isSticky
          title={title}
          miscStyles={
            columns
              ? {
                gridColumnEnd: columns.map(colCount => ({
                  ...colCount,
                  value: `span ${colCount.value}`,
                })),
              }
              : null
          }
        />
      ) : null}
      {items.map(({ content, width = [], miscStyles: itemMiscStyles, }) => {
        if (!content) return null;
        const { inputTemplate, preventRender, loadPriority, } = content;
        const Component = getComponent(
          inputTemplate,
          {
            loadPriority,
            preventRender,
            isExpanded: typeof content.isExpanded === 'boolean' ? content.isExpanded : undefined,
            rootMargin: 1000,
            Placeholder: ItemPlaceholder,
          }
        );
        const itemColSpan = width
          ? width.map(colSpan => theme.mq(colSpan, { gridColumnEnd: `span ${colSpan.value}`, })
          )
          : null;
        return (
          <div
            key={content.contentId}
            className={css({
              display: 'grid',
              extend: itemMiscStyles
                ? [
                  ...(itemColSpan || []),
                  ...parseStyleProps(itemMiscStyles, theme.mq, theme.type),
                ]
                : itemColSpan,
            })}
          >
            <Component {...content} />
          </div>
        );
      })}
    </ListView>
  );
}
