/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  parseComponentProp,
  parseStyleProps,
  type ComponentPropResponsiveObject,
  type StyleProps,
} from '@haaretz/htz-css-tools';
import IconMute from '../../Icon/icons/IconUnMuted';
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';
import ButtonFocusCatcher from './ButtonFocusCatcher';
import useAudioPlayerContext from '../useAudioPlayerContext';

type MuteButtonProps = {
  size: number | ComponentPropResponsiveObject<number>[],
  gridArea: ?string,
  diagonalBorderColor:
    | string
    | [ string, string, ],
  miscStyles: ?StyleProps,
};

const muteButtonStyle = ({ isMuted, gridArea, miscStyles, theme, }) => ({
  ':-moz-focusring': { outline: `2px dotted ${theme.color('audioPlayer', 'mozFocusRing')}`, },
  gridArea,
  extend: [
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

const muteButtonInnerStyle = {
  ':-moz-focusring': { outline: 'none', },
  outline: 'none',
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const muteIconWrapperStyle = ({ theme, isMuted, diagonalBorderColor, }) => ({
  position: 'relative',
  ':after': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none',
    top: '1%',
    width: '2px',
    height: '100%',
    backgroundColor: 'currentColor',
    boxShadow: `0 0 0 1px ${theme.color(
      ...(Array.isArray(diagonalBorderColor) ? diagonalBorderColor : [ diagonalBorderColor, ])
    )}`,
    transitionProperty: 'transform',
    transitionDuration: '0.4s',
    transform: `rotate(-45deg) scale(1, ${isMuted ? 1.5 : 0})`,
  },
});

MuteButton.defaultProps = {
  size: 3,
  gridArea: 'mute',
  diagonalBorderColor: [ 'neutral', '-10', ],
  miscStyles: null,
};

function MuteButton({
  size,
  gridArea,
  diagonalBorderColor,
  miscStyles,
}: MuteButtonProps): React.Node {
  const { toggleMute, isMuted, } = useAudioPlayerContext();
  const { css, theme, } = useFela({ isMuted, gridArea, diagonalBorderColor, miscStyles, });
  return (
    <button type="button" onClick={toggleMute} className={css(muteButtonStyle)}>
      <ButtonFocusCatcher onButtonClick={toggleMute} className={css(muteButtonInnerStyle)}>
        <div className={css(muteIconWrapperStyle)}>
          <IconMute
            aria-hidden
            size={size}
            miscStyles={{ display: 'block', }}
          />
        </div>
        <VisuallyHidden>{theme.audioPlayerI18n[isMuted ? 'unmute' : 'mute']}</VisuallyHidden>
      </ButtonFocusCatcher>
    </button>
  );
}

export default MuteButton;
