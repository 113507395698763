// @flow
import React, { useState, type Node, } from 'react';
import { useFela, } from 'react-fela';

import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';
import Button from '../Button/Button';
import H from '../AutoLevels/H';
import Section from '../AutoLevels/Section';
import StripController from './StripController';
import BreakingNewsItem from './BreakingNewsItem';
import useHeaderNewsData from '../../hooks/Page/useHeaderNewsData';
import { useEventTracker, } from '../../utils/EventTracker';


type Props = {
  /** Items duration display time */
  speed: number,
  /** Are items iterate automatically */
  loop: boolean,
  /** The duration of item fade in/out animation */
  itemsTransitionDuration: number,
  miscStyles: ?StyleProps,
};

const wrapperStyle = ({ theme, miscStyles, }) => ({
  ...theme.breakingNewsStyle.wrapperStyle({ theme, }),
  ...theme.mq({ until: 's', }, { display: 'none', }),
  ...theme.mq({ from: 's', }, { display: 'flex', }),
  extend: [
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

BreakingNewsBox.defaultProps = {
  speed: 7,
  loop: true,
  itemsTransitionDuration: 0,
  miscStyles: null,
};
export default function BreakingNewsBox({
  loop,
  speed,
  itemsTransitionDuration,
  miscStyles,
}: Props): Node {
  const [ visibleIndex, setVisibleIndex, ] = useState(0);
  const { biAction, } = useEventTracker();
  const { theme, css, } = useFela({ miscStyles, });

  const breakingNewsBox = useHeaderNewsData('breakingNews');
  const { items, url, } = breakingNewsBox || {};

  return (
    <Section tagName="aside" className={css(wrapperStyle)}>
      <H>
        <Button
          href={url || theme.breakingNewsStrip.url}
          variant={theme.breakingNewsStyle.buttonVariant}
          fontSize={[
            { until: 'l', value: 0, },
            { from: 'l', value: -1, },
          ]}
          boxModel={theme.breakingNewsStyle.buttonBoxModel({ theme, })}
          onClick={() => biAction({
            actionCode: 153,
            featureType: 'Content',
            feature: 'HP Breaking News',
            ViewName: 'HP Breaking News',
          })}
        >
          {theme.breakingNewsStrip.title}
        </Button>
      </H>
      <React.Fragment>
        <ul
          className={css({
            alignItems: 'center',
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            paddingInlineStart: '2rem',
            fontFamily: theme.fontStacks[theme.framedFont],
          })}
        >
          {items?.map((item, index) => {
            const isVisible = index === visibleIndex;
            return (
              <BreakingNewsItem
                key={item.contentId || item.title}
                {...item}
                isVisible={isVisible}
                animationDuration={itemsTransitionDuration}
              />
            );
          })}
        </ul>
        <StripController
          onChange={controllerData => setVisibleIndex(controllerData.index)}
          speed={speed}
          size={items?.length}
          loop={loop}
        />
      </React.Fragment>
    </Section>
  );
}
