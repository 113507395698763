/* noOverWrite */
// Width and height are set so that the icon would blend perfectly with the Muted icon
// May be overwritten if base svgs change so that the two fit perfectly one over the other

/* eslint-disable */
import React from 'react';
import { useFela, } from 'react-fela';
import iconStyle from '../iconStyle';
import { iconPropTypes, iconDefaultProps, } from '../iconPropTypes';

export default function IconUnMuted({ size, fill, color, attrs, miscStyles, ...props }) {
  const className = useFela({ size, fill, color, miscStyles, }).css(iconStyle);

  return (
    <svg width="1em" height="0.8401em" viewBox="0 0 11.76 9.88" className={className} {...props} {...attrs}>  <path fill="currentColor" d="M5.57 9.15c0 .18-.17.27-.4.27s-.57-.23-1-.69l-1.88-2H.46a.44.44 0 01-.32-.13.41.41 0 01-.14-.31V3.61a.41.41 0 01.14-.31.44.44 0 01.32-.13H2.3l1.88-2q.64-.69 1-.69c.23 0 .36.09.4.27a1.91 1.91 0 01.06.42v7.56a1.91 1.91 0 01-.07.42zm1.38-2.54h-.08a.48.48 0 01-.46-.46.48.48 0 01.41-.5q.42-.1.42-.72t-.42-.72a.48.48 0 01-.41-.5.48.48 0 01.46-.46H7.04a1.6 1.6 0 01.8.69 1.87 1.87 0 01.29 1 1.75 1.75 0 01-1.08 1.63zm.72 1.63h-.09a.44.44 0 01-.32-.13.41.41 0 01-.14-.31.42.42 0 01.08-.26.48.48 0 01.2-.15l.27-.14a1.67 1.67 0 00.28-.17 2.76 2.76 0 00.8-.93 2.59 2.59 0 000-2.42 2.76 2.76 0 00-.8-.93 1.67 1.67 0 00-.28-.18l-.27-.14a.48.48 0 01-.2-.15.42.42 0 01-.08-.26.41.41 0 01.14-.31.44.44 0 01.32-.13h.19a3.33 3.33 0 011.58 1.31 3.5 3.5 0 010 4 3.33 3.33 0 01-1.58 1.3zm.72 1.63h-.1a.44.44 0 01-.34-.12.4.4 0 01-.13-.31.33.33 0 01.13-.25.6.6 0 01.19-.16l.13-.09a.6.6 0 00.16-.08 2.11 2.11 0 00.3-.16l.28-.19a4.3 4.3 0 001.36-1.57 4.29 4.29 0 000-4 4.3 4.3 0 00-1.36-1.58l-.28-.19a2.11 2.11 0 00-.3-.16.6.6 0 00-.16-.07.8.8 0 01-.16-.09.6.6 0 01-.16-.16.33.33 0 01-.11-.25.4.4 0 01.11-.31.44.44 0 01.34-.13h.19a5.44 5.44 0 012.39 2 5.21 5.21 0 01.89 2.94 5.21 5.21 0 01-.89 2.94 5.44 5.44 0 01-2.39 2z" /></svg>
  );
}

IconUnMuted.propTypes = iconPropTypes;
IconUnMuted.defaultProps = iconDefaultProps;
