// @flow
/* global window */
import * as React from 'react';
import { useFela, } from 'react-fela';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import setBiAction from '../../../../utils/setBiAction';
import Section from '../../../AutoLevels/Section';
import MarcoTeaser from './MarcoTeaser';

import { type ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import { type ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';
import setBiImpression from '../../../../utils/setBiImpression';
import { type ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  list: ListDataType,
  isLazyloadImages: boolean,
  gaAction: () => void,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
};

MarcoView.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
  biImpression: null,
};

function MarcoView({ list, isLazyloadImages, biAction, gaAction, biImpression, }: Props): React.Node {
  const { items, extraLinks, ...restOfList } = list;
  const { css, theme, } = useFela();

  if (!(items && items.length)) return null;

  const contentClasses = css({
    gridArea: 'content',
    display: 'grid',
    gridGap: '2rem',
    extend: [ theme.mq({ from: 's', }, { gridGap: '4rem', }), ],
  });
  return (
    <ListView
      marginTop={[ { until: 'l', value: 0, }, { from: 'l', value: 6, }, ]}
      areasTemplate={[
        { until: 'l', value: '"he" "content"', },
        { from: 'l', value: '"he content"', },
      ]}
      innerBackgroundColor={[ 'marco', 'bg', ]}
      colTemplate={[ { from: 'l', value: '1fr 5fr', }, ]}
      attrs={{
        'data-test': 'marco',
      }}
    >
      <ListViewHeader {...restOfList} biAction={biAction} isSticky={false} miscStyles={{ display: [ { until: 'l', value: 'none', }, ], }} />
      <Section tagName="div" className={contentClasses}>
        {items.map((item, i) => (item ? (
          <MarcoTeaser
            key={item.contentId}
            data={item}
            isLazyloadImages={isLazyloadImages}
            biAction={setBiAction(i, item, biAction)}
            biImpression={setBiImpression(i, item, biImpression)}
            theme={theme}
          />
        ) : null)
        )}
      </Section>
    </ListView>
  );
}

export default MarcoView;
