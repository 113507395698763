// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import HtzLink from '../HtzLink/HtzLink';
import IconRss from '../Icon/icons/IconRss';
import usePodcastChannelType from '../../hooks/usePodcastChannelType';

type Props = {
  channelLinks: {
    spotify: string,
    google: string,
    apple: string,
  },
  miscStyles: ?StyleProps,
};

ArticleBodyAudioRss.defaultProps = {
  miscStyles: null,
};

function ArticleBodyAudioRss({ channelLinks, miscStyles, }: Props) {
  const channel = usePodcastChannelType();
  const { theme, css, } = useFela();

  return (
    <HtzLink
      target="_blank"
      rel="noopener noreferrer"
      href={channelLinks[channel]}
      className={css({
        gridArea: 'rss',
        fontWeight: 700,
        extend: [
          theme.type(-2, { untilBp: 's', }),
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      <IconRss
        size={[ { until: 'xl', value: 3.67, }, { from: 'xl', value: 3.15, }, ]}
        miscStyles={{ marginInlineEnd: '0.5rem', }}
      />
      {theme.podcastI18n.rssLinkText}
    </HtzLink>
  );
}

export default ArticleBodyAudioRss;
