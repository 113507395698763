import React, { Fragment, } from 'react';
import { useFela, } from 'react-fela';
import PropTypes from 'prop-types';

import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';

import Caption from '../Caption/Caption';
import Image from '../Image/Image';
import Picture from '../Image/Picture';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import ApolloBoundaryConsumer from '../ApolloBoundary/ApolloConsumer';
import EnlargementWrapper from './EnlargementWrapper';
import HtzLink from '../HtzLink/HtzLink';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import { formatImageTitle, getImageAspect, } from '../Image/utils';
import useOneTime from '../../hooks/useOneTime';

const articleImagePropTypes = {
  /**
   * A mutation function that passes from `AddImageToSchema` mutation.
   */
  addImageToSchema: PropTypes.func.isRequired,
  /** The image's aspect ratio to use as base crop, default 'full' */
  aspects: PropTypes.string.isRequired,
  /** props to pass on to the caption */
  captionProps: PropTypes.shape({}),
  /**
   * react-fela class names
   */
  className: PropTypes.string,
  /** Image id from polopoly */
  contentId: PropTypes.string.isRequired,
  /** Image name from polopoly */
  contentName: PropTypes.string.isRequired,
  /**
   * Image's credit.
   */
  credit: PropTypes.string,
  /**
   * editor's aspect choice.
   */
  aspect: PropTypes.string,
  /**
   * Force the image to render in a specific aspect, regardless of the editor's choice.
   */
  forceAspect: PropTypes.string,
  /**
   * Is it a standard image or infographic.
   */
  imageType: PropTypes.string.isRequired,
  /**
   * Image's dada that comes from polopoly.
   */
  imgArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * A function that gets the aspect and isFullScreen args
   * should return an objects of imgOptions the includes sizes and transforms
   */
  imgOptions: PropTypes.func.isRequired,
  /**
   * Should the image be rendered as full-screen.
   */
  isFullScreen: PropTypes.bool,
  /**
   * Is the image is a headline (Position 0).
   */
  isHeadline: PropTypes.bool,
  /**
   * Is this image is the last item in the article body (needed for margin bottom).
   */
  lastItem: PropTypes.bool,
  /**
   * A special property holding miscellaneous CSS values that
   * trumps all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
  /**
   * Should the image open an Image gallery on click.
   */
  shouldOpenGallery: PropTypes.bool,
  /**
   * Should the image be wrapped with url in app
   */
  shouldDisableImageAppUrl: PropTypes.bool,
  /**
   * Should the image be rendered with its caption.
   */
  showCaption: PropTypes.bool,
  /**
   * Image photographer alternative credit.
   */
  photographer: PropTypes.string,
  /**
   * Image's title.
   */
  title: PropTypes.string,
  /**
   * The image's selected view mode set by the editor (regular, full or 1/3).
   */
  viewMode: PropTypes.string.isRequired,
  /**
   * Forced disable lazy loading
   */
  disableLazyLoad: PropTypes.bool,
  /**
   * a function that returns a component to render.
   */
  render: PropTypes.func,
  /**
   * Is this image is one of gallery images
   */
  isGalleryItem: PropTypes.bool,
  /**
   * Is this image is one of gallery images
   */
  isMagazineArticle: PropTypes.bool,
  /**
   * Is this image left or right position
   */
  isLeftOrRight: PropTypes.bool,
  /**
   * Is this image mid max position
   */
  isMidMaxPosition: PropTypes.bool,
};

const articleImageDefaultProps = {
  captionProps: null,
  className: null,
  credit: null,
  forceAspect: null,
  isFullScreen: false,
  isHeadline: false,
  lastItem: false,
  miscStyles: {},
  shouldOpenGallery: true,
  shouldDisableImageAppUrl: false,
  showCaption: true,
  photographer: null,
  title: null,
  disableLazyLoad: false,
  render: null,
  isGalleryItem: false,
  isMagazineArticle: false,
  isLeftOrRight: false,
  isMidMaxPosition: false,
  aspect: null,
};

const imagePropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object.isRequired,
  /**
   * Force the image to render in a specific aspect, regardless of the editor's choice.
   */
  forceAspect: PropTypes.string,
  /**
   * Is it a standard image or infographic.
   */
  aspect: PropTypes.string.isRequired,
  /**
   * A function that gets the aspect and isFullScreen args
   * should return an objects of imgOptions the includes sizes and transforms
   */
  imgOptions: PropTypes.func.isRequired,
  /**
   * Should the view switch to full-screen.
   */
  isFullScreen: PropTypes.bool,
  /**
   * The image's selected view mode set by the editor (regular, full or 1/3).
   */
  viewMode: PropTypes.string,
  /**
   * Should the image be lazy loaded
   */
  lazyLoad: PropTypes.bool,

  /* Image description */

  accessibility: PropTypes.string,

  /* Image onload callback */
  onLoad: PropTypes.func,
};

const imageDefaultProps = {
  isFullScreen: false,
  viewMode: null,
  forceAspect: null,
  accessibility: null,
  lazyLoad: false,
  aspect: 'full',
  onLoad: null,
};

const mediaQueryCallback = (prop, value) => ({ [prop]: value, });

const wrapperStyle = ({
  lastItem,
  isHeadline,
  miscStyles,
  theme,
  isFullScreen,
  isVertical,
  isLeftOrRight,
  isMagazineVertical,
}) => ({
  position: 'relative',
  display: 'block',
  width: '100%',
  flexShrink: '0',
  ...(isFullScreen
    ? {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
    : {}),
  extend: [
    theme.mq(
      { from: 's', misc: 'portrait', },
      {
        ...(isFullScreen ? { display: 'block', } : {}),
      }
    ),
    theme.mq(
      { from: 'm', misc: 'landscape', },
      {
        ...(isFullScreen ? { display: 'block', } : {}),
      }
    ),
    ...(!lastItem && !isHeadline
      ? [
        parseComponentProp(
          'marginBottom',
          theme.articleStyle.body.marginBottom,
          theme.mq,
          mediaQueryCallback
        ),
      ]
      : []),
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    // image is left or right side of magazine article body
    isLeftOrRight && !isFullScreen
      ? {
        width: '100%',
        extend: [
          theme.mq(
            { until: 's', },
            { gridTemplateColumns: '26.5rem 1fr', display: 'grid', }
          ),
          theme.mq(
            { from: 's', until: 'l', },
            { gridTemplateColumns: '34.3rem 1fr', display: 'grid', }
          ),
        ],
      }
      // vertical Image and not magazine article image
      : !isMagazineVertical && isVertical && !isFullScreen
        ? {
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '34.3rem 1fr',
          extend: [
            theme.mq({ until: 's', }, { gridTemplateColumns: '26.5rem 1fr', }),
            theme.mq(
              { from: 's', },
              { alignItems: 'end', gridTemplateColumns: '34.3rem 1fr', }
            ),
          ],
        }
        : {},
  ],
});

const ImageElement = props => {
  const {
    isFullScreen,
    viewMode,
    forceAspect,
    imgOptions,
    lazyLoad,
    aspect,
    onLoad,
    ...restImage
  } = props;

  const image = props.image || restImage || {};
  const { files, type, } = image;

  if (!Array.isArray(files) || files.length < 1) {
    return null;
  }

  const imgAspect = type === 'infographic'
    ? 'full'
    : aspect || forceAspect || getImageAspect(viewMode) || 'full';

  const sourceAndImageOptions = imgOptions
    ? imgOptions(imgAspect, isFullScreen)
    : {
      sizes: '100vw',
      transforms: files.map(file => ({
        width: file.width,
        aspect: imgAspect,
        quality: 'auto',
      })),
    };

  return [ 'infographic', ].includes(type) ? (
    <Picture
      lazyLoad={lazyLoad}
      hasWrapper={!isFullScreen}
      image={image}
      defaultImg={{
        positionInImgArray: 1,
        sourceOptions: sourceAndImageOptions,
      }}
      sources={[
        {
          from: 's',
          positionInImgArray: 0,
          sourceOptions: sourceAndImageOptions,
        },
      ]}
      bgcolor={isFullScreen ? 'neutral' : ''}
      onLoad={onLoad}
    />
  ) : (
    <Image
      lazyLoad={lazyLoad}
      hasWrapper={!isFullScreen}
      image={image}
      attrs={{ alt: props.accessibility, 'data-test': 'articleBodyImage', }}
      imgOptions={sourceAndImageOptions}
      bgcolor={isFullScreen ? 'neutral' : ''}
      onLoad={onLoad}
    />
  );
};

ImageElement.propTypes = imagePropTypes;
ImageElement.defaultProps = imageDefaultProps;

const openGallery = ({ client, contentId, }) => client.writeData({
  data: {
    pageGallery: {
      isOpen: true,
      startWith: contentId,
      __typename: 'PageGallery',
    },
  },
});

const verticalCaptionMiscStyles = {
  paddingInlineStart: '2rem',
  display: 'flex',
  flexDirection: 'column',
};

/**
 * The ArticleImage component takes the Image/Picture component, strips it
 * from it's wrapper and wraps it with a specific new one, according to the
 * image's Meta (which is unique per article), and adds a [`full-screen`](./#fullscreenmedia) option as
 * a default.
 */
function ArticleBodyImage({
  captionProps,
  className,
  imgOptions,
  isFullScreen,
  isHeadline,
  lastItem,
  miscStyles,
  showCaption,
  shouldOpenGallery,
  shouldDisableImageAppUrl,
  forceAspect,
  viewMode,
  disableLazyLoad,
  render,
  isGalleryItem,
  isMagazineArticle,
  isLeftOrRight,
  isMidMaxPosition,
  aspect,
  wrapRef,
  ...image
}) {
  const isWebView = useWebViewChecker();
  const { css, theme, } = useFela();

  const lazyLoad = disableLazyLoad ? false : !(isHeadline || isFullScreen);
  const { caption, credit, } = image.image || image || {};

  const isVertical = [ 'OneThirdView', 'TwoThirdView', 'vertical', ].includes(
    viewMode
  );
  const isMagazineVertical = isMagazineArticle && isVertical;

  const imageCaption = formatImageTitle({ caption, theme, }) || '';
  const {
    galleryI18n,
  } = theme;

  if (isWebView && !isGalleryItem) {
    const LinkComponent = shouldDisableImageAppUrl ? React.Fragment : HtzLink;
    return (
      <LinkComponent href={`/ty-image/${image.contentId}?img&_app=true`}>
        <figure
          className={`${className || ''}} ${css(prop => wrapperStyle({
            ...prop,
            isFullScreen,
            isHeadline,
            isVertical,
            isLeftOrRight,
            isMagazineVertical,
            lastItem,
            miscStyles,
            viewMode: forceAspect || viewMode,
          })
          )}`}
        >
          {render ? (
            render()
          ) : (
            <Fragment>
              <ImageElement
                imgOptions={imgOptions}
                forceAspect={forceAspect}
                viewMode={viewMode}
                {...image}
                isFullScreen={isFullScreen}
                lazyLoad={lazyLoad}
                aspect={aspect}
              />
              {showCaption && !isFullScreen ? (
                <Caption
                  caption={imageCaption || ''}
                  credit={credit}
                  miscStyles={
                    // image is left or right side of magazine article body
                    isLeftOrRight
                      ? {
                        ...theme.mq(
                          { from: 'l', },
                          {
                            marginTop: '1rem',
                            paddingInlineStart: 0,
                            flexDirection: 'column',
                          }
                        ),
                      }
                      // image is in the middle of magazine article body
                      : isMidMaxPosition
                        ? {
                          ...theme.mq(
                            { from: 's', until: 'l', },
                            {
                              marginTop: '1rem',
                              marginInlineStart: '2rem',
                            }
                          ),
                        }
                        // vertical Image and not magazine article image
                        : isVertical && !isMagazineArticle
                          ? verticalCaptionMiscStyles
                          : {
                            paddingInlineStart: 0,
                            marginTop: '1rem',
                          }
                  }
                  {...(captionProps || {})}
                />
              ) : null}
            </Fragment>
          )}
        </figure>
      </LinkComponent>
    );
  }

  return (
    <figure
      ref={wrapRef}
      className={`${className || ''}} ${css(prop => wrapperStyle({
        ...prop,
        viewMode: forceAspect || viewMode,
        lastItem,
        isHeadline,
        miscStyles,
        isFullScreen,
        isLeftOrRight,
        isMagazineVertical,
        isVertical,
      })
      )}`}
    >
      {shouldOpenGallery && !isFullScreen ? (
        <ApolloBoundaryConsumer>
          {client => (
            <EnlargementWrapper
              isFullScreen={isFullScreen}
              onClick={() => openGallery({ client, contentId: image.contentId, })
              }
              iconText={galleryI18n.open}
            >
              {render ? (
                render()
              ) : (
                <ImageElement
                  imgOptions={imgOptions}
                  forceAspect={forceAspect}
                  viewMode={viewMode}
                  {...image}
                  isFullScreen={isFullScreen}
                  lazyLoad={lazyLoad}
                  aspect={aspect}
                />
              )}
            </EnlargementWrapper>
          )}
        </ApolloBoundaryConsumer>
      ) : render ? (
        render()
      ) : (
        <ImageElement
          imgOptions={imgOptions}
          forceAspect={forceAspect}
          viewMode={viewMode}
          {...image}
          isFullScreen={isFullScreen}
          lazyLoad={lazyLoad}
          aspect={aspect}
        />
      )}
      {showCaption && !isFullScreen ? (
        <Caption
          caption={imageCaption || ''}
          credit={credit}
          captionMiscStyles={
            isLeftOrRight
              ? { display: 'block', }
              : {}
          }
          miscStyles={
            // image is left or right side of magazine article body
            isLeftOrRight
              ? {
                ...theme.mq(
                  { from: 'l', },
                  {
                    marginTop: '1rem',
                    paddingInlineStart: 0,
                    flexDirection: 'column',
                  }
                ),
              }
              // image is in the middle of magazine article body
              : isMidMaxPosition
                ? {
                  ...theme.mq(
                    { from: 's', until: 'l', },
                    {
                      marginTop: '1rem',
                      marginInlineStart: '2rem',
                    }
                  ),
                }
                // vertical Image and not magazine article image
                : isVertical && !isMagazineArticle
                  ? verticalCaptionMiscStyles
                  : {
                    paddingInlineStart: 0,
                    marginTop: '1rem',
                  }
          }
          {...(captionProps || {})}
        />
      ) : null}
    </figure>
  );
}

ArticleBodyImage.propTypes = articleImagePropTypes;
ArticleBodyImage.defaultProps = articleImageDefaultProps;

export default ({
  includeData,
  imageUrlLinkGroup,
  imageUrlLinkGroupTarget,
  biAction,
  biImpression,
  wrapRef,
  inView,
  ...props
}) => {
  useOneTime(includeData && !!inView && !!biImpression, () => {
    biImpression();
  });

  return imageUrlLinkGroup ? (
    includeData ? (
      <HtzLink
        href={imageUrlLinkGroup}
        target={imageUrlLinkGroupTarget}
        onClick={biAction}
        ref={wrapRef}
      >
        <ArticleBodyImage
          {...props}
          shouldOpenGallery={false}
          shouldDisableImageAppUrl
        />
      </HtzLink>
    ) : (
      <HtzLink href={imageUrlLinkGroup} target={imageUrlLinkGroupTarget}>
        <ArticleBodyImage
          {...props}
          shouldOpenGallery={false}
          shouldDisableImageAppUrl
        />
      </HtzLink>
    )
  ) : (
    <ArticleBodyImage {...props} wrapRef={wrapRef} />
  );
};
