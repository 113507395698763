// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import BasePlaceholder from '../BasePlaceholder/BasePlaceholder';

const styles = {
  height: '40rem',
  position: 'relative',
  width: '100%',
};

// let className: ?string;

function ItemPlaceholder() {
  const { css, } = useFela();

  const className = css(styles);

  return (
    <div className={className}>
      <BasePlaceholder isAbsolute />
    </div>
  );
}

function neverRerender() {
  return true;
}
export default React.memo<any>(ItemPlaceholder, neverRerender);
