/* global navigator window */

export const mobileOSTypes = Object.freeze({
  android: 'Android',
  apple: 'iOs',
  windows: 'Windows Phone',
  unknown: 'unknown',
});

// from: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
export default function checkMobileOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return mobileOSTypes.windows;
  }

  if (/android/i.test(userAgent)) {
    return mobileOSTypes.android;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return mobileOSTypes.apple;
  }

  return mobileOSTypes.unknown;
}
