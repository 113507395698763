// @flow
import * as React from 'react';
import { useInView, } from 'react-intersection-observer';

import { type TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import Image from '../../../Image/Image';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserSubtitle from '../../../TeaserSubtitle/TeaserSubtitle';
import getImageAssets from '../../../../utils/getImageAssets';


type Props = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  biImpression: ?() => void,
  theme: Object,
};

function MarcoTeaser({ data, isLazyloadImages, biAction, theme, biImpression, }: Props): React.Node {
  const image = data?.mobileImage || data?.image;
  const [ isImpressionSent, setIsImpressionSent, ] = React.useState(false);
  const [ ref, inView, ] = useInView({ triggerOnce: true, threshold: 0.9, });

  React.useEffect(() => {
    if (inView && biImpression && !isImpressionSent) {
      biImpression();
      setIsImpressionSent(true);
    }
  }, [ biImpression, isImpressionSent, inView, ]);

  return (
    <Teaser
      fwRef={ref}
      data={data}
      areasTemplate={`
      "media    .    ."
      "media content ."
      "media footer  ."
      "media    .    ."
      `}
      gridGap="1rem 2rem"
      colTemplate={[
        { until: 's', value: '24rem 1fr 0', },
        { from: 's', until: 'l', value: '33rem 1fr 0', },
        { from: 'l', until: 'xl', value: '38rem 1fr 0', },
        { from: 'xl', value: '41rem 1fr 0', },
      ]}
      rowTemplate="0 1fr auto 0"
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Image
          lazyLoad={isLazyloadImages}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'regular',
            sizes: [
              { from: 'xl', size: '284px', },
              { from: 'l', size: '228px', },
              { from: 's', size: '196px', },
              { size: '144px', },
            ],
            widths: [ 284, 228, 196, 144, ],
          })}
          image={image}
        />
      </TeaserMedia>
      <TeaserContent tagName="header">
        <TeaserHeader
          {...data}
          typeScale={theme.marcoStyle.teaser.headerTypeScale}
          onClick={biAction}
        />
        {data.subtitle ? (
          <TeaserSubtitle
            {...data}
            miscStyles={{
              display: [ { until: 'l', value: 'none', }, ],
              marginTop: [ { from: 'l', value: '1rem', }, ],
              fontWeight: 400,
            }}
          />
        ) : null}
      </TeaserContent>
      <TeaserFooter data={data} showAuthor />
    </Teaser>
  );
}

export default MarcoTeaser;
