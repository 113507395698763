import React, { useState, } from 'react';
import PropTypes from 'prop-types';
import { FelaComponent, useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import ArticleImage from '../ArticleBodyImage/ArticleBodyImage';
import { buildUrl, } from '../../utils/buildImgURLs';
import Caption from '../Caption/Caption';
import Carousel from '../Carousel/Carousel';
import FullScreenMedia from '../FullScreenMedia/FullScreenMedia';
import IconBack from '../Icon/icons/IconBack';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import IconCamera from '../Icon/icons/IconCamera';
import HtzLink from '../HtzLink/HtzLink';
import useDarkModeChecker from '../../hooks/useDarkModeChecker';

const propTypes = {
  /**
   * Gallery's title/name for Aria.
   */
  accessibility: PropTypes.string.isRequired,
  /**
   * The duration of the sliding animation.
   */
  animationDuration: PropTypes.number,
  /**
   * Disables you to see the gallery in full-screen.
   */
  disableFullScreen: PropTypes.bool,
  /**
   * Force the images in the gallery to render in a specific aspect,
   * regardless of the gallery's default.
   */
  forceAspect: PropTypes.string,
  /**
   * An array of images' objects you want to display in the gallery.
   */
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Should the gallery be rendered as full-screen only.
   */
  fullScreenOnly: PropTypes.bool,
  /**
   * A method that should be executed when closing full-screen.
   */
  exitFullScreenAction: PropTypes.func,
  /**
   * Gallery's title/name for display (if enabled).
   */
  name: PropTypes.string.isRequired,
  /**
   * A render prop that will trump the default caption and lice(indicator).
   */
  renderCaption: PropTypes.func,
  /**
   * At what index should the gallery start.
   */
  startAt: PropTypes.number,
};

const galleryProps = {
  ...propTypes,
  /**
   * Current displaying image's index.
   */
  currentDisplaying: PropTypes.number.isRequired,
};

const defaultProps = {
  animationDuration: 3,
  disableFullScreen: false,
  forceAspect: null,
  fullScreenOnly: false,
  isFullScreen: false,
  renderCaption: null,
  exitFullScreenAction: null,
  startAt: 0,
  images: [],
};

const buildImgOptions = (aspect, isFullScreen) => ({
  sizes: isFullScreen
    ? '100vw'
    : '(min-width:1280px) 899px,(min-width:1024px) 1024px,(min-width:768px) 768px,(min-width:600px) 600px, calc(100vw - 36px)',
  transforms: [
    {
      width: '350',
      aspect,
      quality: 'auto',
    },
    {
      width: '490',
      aspect,
      quality: 'auto',
    },
    {
      width: '600',
      aspect,
      quality: 'auto',
    },

    {
      width: '768',
      aspect,
      quality: 'auto',
    },
    {
      width: '899',
      aspect,
      quality: 'auto',
    },
    {
      width: '1024',
      aspect,
      quality: 'auto',
    },
    {
      width: '1280',
      aspect,
      quality: 'auto',
    },
    {
      width: '1429',
      aspect,
      quality: 'auto',
    },
  ],
});

const captionWrapperStyle = ({
  theme,
  position,
  moving,
  direction,
  isFullScreen,
  animationDuration,
  miscStyles,
}) => {
  const positionChange = direction === 'next' ? 100 : direction === 'previous' ? -100 : 0;

  return {
    marginBottom: '3rem',
    paddingInlineEnd: '1rem',
    paddingInlineStart: '1rem',
    position: isFullScreen ? 'static' : 'absolute',
    textAlign: 'start',
    top: '1rem',
    left: '0',
    display: 'flex',
    transform: `translateX(${position + positionChange}%)`,
    width: '100%',
    ...(moving
      ? {
        transitionProperty: 'all',
        ...theme.getDuration('transition', animationDuration),
        ...theme.getTimingFunction('transition', 'swiftOut'),
      }
      : {}),
    extend: [
      // Trump all other styles with those defined in `miscStyles`
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
};

const switchButtonStyles = ({ isFullScreen, }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  extend: [ isFullScreen ? { display: 'none', } : {}, ],
});

// eslint-disable-next-line react/prop-types
const DotsElement = ({ images, displayItemNum, miscStyles, }) => (
  <FelaComponent
    style={({ theme, }) => ({
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
    })}
  >
    {images.map((img, i) => (
      <FelaComponent
        style={({ theme, }) => ({
          backgroundColor:
            i === displayItemNum
              ? theme.color('gallery', 'highlight')
              : theme.color('neutral', '-6', 0.5),
          borderRadius: '50%',
          display: 'inline-block',
          height: '1.3rem',
          marginStart: '1rem',
          marginEnd: '1rem',
          width: '1.3rem',
        })}
      >
        {({ className, }) => <span className={className} />}
      </FelaComponent>
    ))}
  </FelaComponent>
);

const nextButtonStyles = ({ theme, isBack = false, }) => ({
  width: '4rem',
  height: '9rem',
  zIndex: '3',
  color: theme.color('neutral', '-1'),
  backgroundColor: theme.color('gallery', 'highlight', 0.8),
  [isBack ? 'start' : 'end']: '0',
  ':focus': {
    outline: 'none',
  },
});

/* eslint-disable react/prop-types */
export const CaptionElement = ({
  isLtr,
  animationDuration,
  caption,
  credit,
  index,
  isFullScreen,
  itemsLength,
  size,
  creditSize,
  position,
  moving,
  wrapperMiscStyles,
  captionMiscStyles,
  prefixContent,
  ...props
}) => {
  const direction = props.direction && isLtr ? props.direction === 'next' ? 'previous' : 'next' : props.direction;

  return (
  /* eslint-enable react/prop-types */
    <FelaComponent
      position={position}
      moving={moving}
      direction={direction}
      isFullScreen={isFullScreen}
      animationDuration={animationDuration}
      miscStyles={wrapperMiscStyles}
      style={captionWrapperStyle}
      data-test="caption"
    >
      {({ className, theme, }) => (
        <div className={className}>
          <FelaComponent
            style={{
              ...theme.type(size),
              color: theme.color('gallery', 'highlight'),
              fontWeight: '700',
              flexShrink: '0',
              marginEnd: '1rem',
            }}
            as="span"
          >
            {prefixContent || theme.galleryI18n.captionPrefix(index + 1, itemsLength)}
          </FelaComponent>
          <Caption
            caption={caption}
            credit={credit}
            color={[ 'neutral', '-10', ]}
            miscStyles={{
              overflow: 'hidden',
              display: isFullScreen ? 'static' : 'flex',
              ...(captionMiscStyles || {}),
            }}
            captionMiscStyles={
            !isFullScreen
              ? {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }
              : {}
          }
            typeStyles={size}
            creditTypeStyles={creditSize}
          />
        </div>
      )}
    </FelaComponent>
  );
};

function Gallery({
  accessibility,
  animationDuration,
  currentDisplaying,
  forceAspect,
  images,
  renderCaption,
  fullScreenOnly,
  exitFullScreenAction,
  disableFullScreen,
  contentId,
  inputTemplate,
}) {
  const { css, theme, } = useFela();
  const isWebView = useWebViewChecker();
  const isDarkMode = useDarkModeChecker();

  if (!Array.isArray(images) || !images.length) return null;

  if (isWebView) {
    return (
      <HtzLink href={`/ty-gallery/${contentId}?gallery&_app=true`}>
        <div className={css({
          position: 'relative',
          marginBottom: '4rem',
        })}
        >
          <div className={css({
            position: 'absolute',
            start: '1rem',
            top: '1rem',
            padding: '0.5rem 0.9rem',
            backgroundColor: theme.color('bg', 'base'),
            color: isDarkMode ? 'inherit' : theme.color('neutral', '-1'),
            zIndex: 1,
            borderRadius: '50%',
          })}
          >
            <IconCamera size={3} />
          </div>
          <ArticleImage
            forceAspect="full"
            isFullScreen
            showCaption={false}
            ignoreSchema
            shouldOpenGallery={false}
            imgOptions={aspect => buildImgOptions(aspect, true)}
            isGalleryItem
            miscStyles={{
              textAlign: 'center',
              marginBottom: '0 !important',
              height: '100%',
              userSelect: 'none',
              '&>img': [
                {
                  userSelect: 'none',
                },
                {
                  until: 'l',
                  misc: 'landscape',
                  value: { objectFit: 'contain', maxHeight: '100vh', },
                },
              ],
            }}
            {...images[0]}
          />
        </div>
      </HtzLink>
    );
  }

  const isLtr = theme.direction === 'ltr';

  return (
    <Carousel
      isLtr={isLtr}
      animationDuration={animationDuration}
      itemsLength={images.length}
      loop
      miscStyles={{
        height: '100%',
        position: 'relative',
      }}
      startAt={currentDisplaying}
      render={({
        renderPreviousItems,
        renderNextItems,
        renderCurrentItems,
        renderButton,
        renderIndicator,
        displayItemNum,
        direction,
        moving,
        ...props
      }) => {
        const { previousItemIndex, nextItemIndex, } = isLtr ? { previousItemIndex: props.nextItemIndex, nextItemIndex: props.previousItemIndex, } : props;

        const image = images[displayItemNum];
        const previousImage = images[previousItemIndex];
        const nextImage = images[nextItemIndex];

        if (!image?.files?.[0]) return null;

        return (
          <FullScreenMedia
            itemName={image.contentName}
            fullScreenOnly={fullScreenOnly}
            disableFullScreen={disableFullScreen}
            exitAction={exitFullScreenAction}
            itemUrl={buildUrl(image.contentId, image.files[0], {
              width: '1920',
              aspect: 'full',
              quality: 'auto',
            })}
            captionElement={(
              <CaptionElement
                isLtr={isLtr}
                animationDuration={animationDuration}
                caption={image.title}
                credit={image.credit}
                index={displayItemNum}
                isFullScreen
                itemsLength={images.length}
                size={-1}
              />
            )}
            render={({ isFullScreen, toggleFullScreen, }) => {
              const Image = ({ current, ...imageProps }) => (isFullScreen ? (
                <ArticleImage
                  forceAspect="full"
                  isFullScreen
                  showCaption={false}
                  ignoreSchema
                  shouldOpenGallery={false}
                  imgOptions={aspect => buildImgOptions(aspect, true)}
                  miscStyles={{
                    textAlign: 'center',
                    marginBottom: '0 !important',
                    height: '100%',
                    userSelect: 'none',
                    '&>img': [
                      {
                        userSelect: 'none',
                      },
                      {
                        until: 'l',
                        misc: 'landscape',
                        value: { objectFit: 'contain', maxHeight: '100vh', },
                      },
                    ],
                  }}
                  {...imageProps}
                />
              ) : (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div onClick={!disableFullScreen ? toggleFullScreen : null}>
                  <ArticleImage
                    disableLazyLoad
                    forceAspect={forceAspect || 'regular'}
                    isFullScreen={false}
                    showCaption={false}
                    ignoreSchema
                    shouldOpenGallery={false}
                    imgOptions={aspect => buildImgOptions(aspect, false)}
                    miscStyles={{
                      textAlign: 'center',
                      marginBottom: '0 !important',
                      userSelect: 'none',
                    }}
                    {...imageProps}
                  />
                </div>
              ));

              return (
                <div
                  className={css({
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    extend: [ theme.mq({ until: 's', }, { justifyContent: 'center', }), ],
                  })}
                  data-test="imageGallery"
                >
                  <div
                    className={css({
                      // *** This next comment-out seems to fix a display bug in Safari 10 and earlier,
                      // *** but its affects should be tested more thoroughly, as this component has many display modes.
                      // height: '100%',
                      display: 'flex',
                      flexWrap: 'nowrap',
                      position: 'relative',
                      ...(isFullScreen
                        ? {
                          ...theme.mq(
                            { from: 's', misc: 'portrait', },
                            {
                              paddingBottom: '8rem',
                            }
                          ),
                        }
                        : {}),
                      extend: [
                        theme.mq({ from: 's', misc: 'portrait', }, { flexShrink: '1', }),
                        theme.mq({ from: 'm', misc: 'landscape', }, { flexShrink: '1', }),
                      ],
                    })}
                  >
                    {renderPreviousItems(
                      ({ itemIndex, }) => (
                        <Image {...images[itemIndex]} />
                      ),
                      isFullScreen
                        ? {
                          height: [
                            { from: 'l', misc: 'landscape', value: 'calc(100vh - 8rem)', },
                            // { until: 'l', misc: 'landscape', value: '100%', },
                          ],
                        }
                        : {}
                    )}
                    {renderCurrentItems(
                      image.alt || image.title,
                      ({ itemIndex, }) => (
                        <Image {...images[itemIndex]} current />
                      ),
                      isFullScreen
                        ? {
                          height: [
                            { from: 'l', misc: 'landscape', value: 'calc(100vh - 8rem)', },
                            // { until: 'l', misc: 'landscape', value: '100%', },
                          ],
                        }
                        : {}
                    )}
                    {renderNextItems(
                      ({ itemIndex, }) => (
                        <Image {...images[itemIndex]} />
                      ),
                      isFullScreen
                        ? {
                          height: [
                            { from: 'l', misc: 'landscape', value: 'calc(100vh - 8rem)', },
                            // { until: 'l', misc: 'landscape', value: '100%', },
                          ],
                        }
                        : {}
                    )}
                    <div
                      className={css({
                        ...switchButtonStyles({ isFullScreen, }),
                        start: '0',
                      })}
                    >
                      {renderButton(({ changeItem, }) => (
                        <button
                          type="button"
                          className={css(nextButtonStyles({ isBack: true, theme, }))}
                          onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            changeItem('previous');
                          }}
                          data-test="toPrevious"
                        >
                          <VisuallyHidden>{theme.imageGallery.rightArrow}</VisuallyHidden>
                          <IconBack
                            size={2.5}
                            miscStyles={
                              isLtr
                                ? null
                                : {
                                  transform: 'rotateY(180deg)',
                                }
                            }
                          />
                        </button>
                      ))}
                    </div>
                    <div
                      className={css({
                        ...switchButtonStyles({ isFullScreen, }),
                        end: '0',
                      })}
                    >
                      {renderButton(({ changeItem, }) => (
                        <button
                          type="button"
                          className={css(nextButtonStyles)}
                          onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            changeItem('next');
                          }}
                          data-test="toNext"
                        >
                          <VisuallyHidden>{theme.imageGallery.leftArrow}</VisuallyHidden>
                          <IconBack
                            size={2.5}
                            miscStyles={
                              isLtr
                                ? {
                                  transform: 'rotateY(180deg)',
                                }
                                : null
                            }
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                  {renderIndicator(() => (isFullScreen ? (
                    <div
                      className={css({
                        display: 'none',
                        position: 'absolute',
                        bottom: '0',
                        right: '50%',
                        transform: 'translateX(50%)',
                        zIndex: 1,
                        extend: [
                          theme.mq(
                            { from: 'm', misc: 'landscape', },
                            {
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }
                          ),
                          theme.mq(
                            { from: 's', misc: 'portrait', },
                            {
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }
                          ),
                        ],
                      })}
                    >
                      {renderButton(({ changeItem, }) => (
                        <button
                          type="button"
                          className={css({
                            width: '4rem',
                            height: '9rem',
                            zIndex: '3',
                            color: theme.color('gallery', 'highlight'),
                            backgroundColor: 'transparent',
                            start: '0',
                          })}
                          onClick={() => changeItem('previous')}
                          aria-label={theme.previousText}
                        >
                          <IconBack
                            size={2.5}
                              // ltr
                            miscStyles={
                                isLtr
                                  ? null
                                  : {
                                    transform: 'rotateY(180deg)',
                                  }
                              }
                          />
                        </button>
                      ))}
                      <DotsElement {...{ images, displayItemNum, }} />
                      {renderButton(({ changeItem, }) => (
                        <button
                          type="button"
                          className={css({
                            width: '4rem',
                            height: '9rem',
                            zIndex: '3',
                            end: '0',
                            color: theme.color('gallery', 'highlight'),
                            backgroundColor: 'transparent',
                          })}
                          onClick={() => changeItem('next')}
                          aria-label={theme.nextText}
                        >
                          <IconBack
                            size={2.5}
                            miscStyles={
                                isLtr
                                  ? {
                                    transform: 'rotateY(180deg)',
                                  }
                                  : null
                              }
                          />
                        </button>
                      ))}
                    </div>
                  ) : renderCaption ? (
                    renderCaption({
                      isFullScreen,
                      renderButton,
                      previousImage,
                      image,
                      nextImage,
                      displayItemNum,
                      previousItemIndex,
                      nextItemIndex,
                      direction,
                      moving,
                      animationDuration,
                      currentDisplaying,
                      itemsLength: images.length,
                    })
                  ) : (
                    <div
                      className={css({
                        backgroundColor: theme.color('neutral'),
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '1rem',
                        position: 'relative',
                        height: '9.5rem',
                      })}
                    >
                      <CaptionElement
                        isLtr={isLtr}
                        animationDuration={animationDuration}
                        caption={previousImage.title}
                        credit={previousImage.credit}
                        direction={direction}
                        index={previousItemIndex}
                        itemsLength={images.length}
                        moving={moving}
                        position={100}
                        size={-2}
                      />
                      <CaptionElement
                        isLtr={isLtr}
                        animationDuration={animationDuration}
                        caption={image.title}
                        credit={image.credit}
                        direction={direction}
                        index={displayItemNum}
                        itemsLength={images.length}
                        moving={moving}
                        position={0}
                        size={-2}
                      />
                      <CaptionElement
                        isLtr={isLtr}
                        animationDuration={animationDuration}
                        caption={nextImage.title}
                        credit={nextImage.credit}
                        direction={direction}
                        index={nextItemIndex}
                        itemsLength={images.length}
                        moving={moving}
                        position={-100}
                        size={-2}
                      />
                      <DotsElement
                        miscStyles={{
                          alignSelf: 'flex-end',
                          marginBottom: '1rem',
                        }}
                        {...{ images, displayItemNum, }}
                      />
                    </div>
                  ))
                  )}
                </div>
              );
            }}
          />
        );
      }}
    />
  );
}

Gallery.propTypes = galleryProps;
Gallery.defaultProps = defaultProps;

/**
 * The ImageGallery component receives an array of images/pictures objects,
 * and mount them in a [`Carousel`](./#carousel) component, with [`ArticleImage`](./#articleimage)
 * component as a renderer.
 */
function ImageGallery({ startAt, ...restOfProps }) {
  const [ currentDisplaying, ] = useState(startAt);
  return <Gallery {...restOfProps} currentDisplaying={currentDisplaying} />;
}

ImageGallery.propTypes = propTypes;
ImageGallery.defaultProps = defaultProps;

export default ImageGallery;
