import { useMemo, } from 'react';
import ArticleImage from '../components/ArticleBodyImage/ArticleBodyImage';
import Embed from '../components/Embed/Embed';
import ImageGallery from '../components/ImageGallery/ImageGallery';
import PodcastEpisode from '../components/Podcast/PodcastEpisode';
import Video from '../components/Video/Video';
import useGetComponent from './GetComponentContext/useGetComponent';

function useGetMediaComponent(
  DefaultImageComponent = ArticleImage,
  DefaultImageGallery = ImageGallery
) {
  const getComponent = useGetComponent();

  const getMediaComponent = useMemo(() => (
    kind,
    ImageComponent = DefaultImageComponent,
    ImageGalleryComponent = DefaultImageGallery
  ) => {
    switch (kind) {
      case 'image':
      case 'htz_image_Image':
      case 'infographic':
        return ImageComponent;
      case 'Gallery':
        return ImageGalleryComponent;
      case 'Video':
        return Video;
      case 'Embed':
        return Embed;
      case 'OmnyStudio':
      case 'articleHeaderAudio':
        return getComponent(kind);
      case 'PodcastEpisode':
        return PodcastEpisode;
      default:
        return () => null;
    }
  }, [ getComponent, DefaultImageComponent, DefaultImageGallery, ]);

  return getMediaComponent;
}

export default useGetMediaComponent;
