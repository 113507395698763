// @flow
import type { StyleProps, } from '@haaretz/htz-css-tools';
import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery, } from 'react-apollo';
import { useFela, createComponent, } from 'react-fela';
import {
  PlayButtonWebView,
} from '../HtzAudioPlayer';
import { getAudioLinkWebView, } from '../HtzAudioPlayer/audioPlayerUtils';
import type { ImageDataType, } from '../../flowTypes/ImageDataType';
import useArticleType from '../../hooks/Page/useArticleType';


const getArticleType = gql`
  query GetArticleType {
    articleType @client
  }
`;

const podcastContainerStyle = {
  position: 'relative',
};

const PodcastContainer = createComponent(podcastContainerStyle);

const blinkContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 0,
};

const BlinkContainer = createComponent(blinkContainerStyle);


const blinkStyle = ({ theme, }) => ({
  position: 'absolute',
  width: '10rem',
  height: '10rem',
  borderRadius: '50%',
  backgroundColor: theme.color('audioPlayerArticle', 'playBgPodcastWebView'),
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  animationDuration: '2s',
  animationIterationCount: 'infinite',
  animationName: {
    '100%': { opacity: 0, transform: 'translate(-50%,-50%) scale(2)', },
  },
});

const Blink = createComponent(blinkStyle, 'span');

const containerStyle = ({ theme, }) => ({
  marginBottom: '4rem',
  backgroundColor: theme.color('audioPlayerArticle', 'bgWebView'),
  display: 'grid',
  gridGap: '2rem',
  gridTemplateColumns: '1fr 8rem',
  padding: '2rem',
  alignItems: 'center',
});

const Container = createComponent(containerStyle);

const textStyle = {
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 700,
};

const Text = createComponent(textStyle);

const titleStyle = ({ theme, }) => ({
  color: theme.color('primary'),
  extend: [
    theme.type(-2),
  ],
});

const Title = createComponent(titleStyle);


const subTitleStyle = ({ theme, }) => ({
  extend: [
    theme.type(-1),
  ],
});

const SubTitle = createComponent(subTitleStyle);


type Props = {
  fileUrl: string,
  contentName: string,
  channelLinks: ?{
    spotify: string,
    google: string,
    apple: string,
  },
  isOmny: boolean,
  channelName: string,
  miscStyles: ?StyleProps,
  isHeadline: boolean,
  loadAfterFirstClick: boolean,
  mobileTitle?: string,
  channelLabel?: string,
  image?: ImageDataType,
  channelImage?: ImageDataType,
};

ArticleBodyAudioWebView.defaultProps = {
  fileUrl: null,
  contentName: null,
  channelLinks: null,
  isOmny: false,
  channelName: null,
  miscStyles: null,
  isHeadline: false,
  loadAfterFirstClick: true,
  mobileTitle: null,
  channelLabel: null,
  image: null,
  channelImage: null,
};

function ArticleBodyAudioWebView({
  fileUrl,
  contentName,
  channelLinks,
  isOmny,
  channelName,
  loadAfterFirstClick,
  miscStyles,
  isHeadline,
  mobileTitle,
  channelLabel,
  image,
  channelImage,
  ...props
}: Props) {
  const { theme, } = useFela();
  const articleType = useArticleType();

  const isPodcatArticle = articleType === 'podcastArticle';

  if (isPodcatArticle) {
    const href = getAudioLinkWebView({
      fileUrl,
      mobileTitle,
      channelLabel,
      channelLinks,
      channelImage,
    });

    return (
      <a href={href}>
        <PodcastContainer>
          <PlayButtonWebView
            color={[ 'audioPlayerArticle', 'playBtnPodcastWebView', ]}
            bgColor={[ 'audioPlayerArticle', 'playBgPodcastWebView', ]}
            diameter="10rem"
            playIconSize={4}
            miscStyles={{
              zIndex: 1,
            }}
          />
          <BlinkContainer>
            <Blink />
          </BlinkContainer>
        </PodcastContainer>
      </a>
    );
  }

  const href = getAudioLinkWebView({
    fileUrl,
    mobileTitle,
    channelLabel,
    channelLinks,
    image,
  });

  return (
    <a href={href}>
      <Container>
        <Text>
          <Title>{theme.audioPlayerI18n.titleWebView(channelLabel)}</Title>
          <SubTitle>{contentName}</SubTitle>
        </Text>
        <PlayButtonWebView
          color={[ 'audioPlayerArticle', 'playBtn', ]}
          bgColor={[ 'audioPlayerArticle', 'playBgWebView', ]}
          diameter="8rem"
          playIconSize={3}
          miscStyles={{
            zIndex: 1,
          }}
        />
      </Container>
    </a>
  );
}
export default ArticleBodyAudioWebView;
