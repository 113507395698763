/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  parseComponentProp,
  parseStyleProps,
  type ComponentPropResponsiveObject,
  type StyleProps,
} from '@haaretz/htz-css-tools';
import IconPlay from '../../Icon/icons/IconPlay';
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';
import { setDiameter, } from '../audioPlayerUtils';

type PlayButtonProps = {
  /** named color from theme */
  color: string | [ string, ] | [ string, string, ],
  /** named color from theme */
  bgColor: string | [ string, ] | [ string, string, ],
  diameter: string | number | ComponentPropResponsiveObject<string | number>,
  playIconSize: number | ComponentPropResponsiveObject<number>[],
  miscStyles: ?StyleProps,
};

const playButtonStyle = ({
  theme,
  bgColor,
  diameter,
  miscStyles,
}) => {
  const backgroundColor = theme.color(...(Array.isArray(bgColor) ? bgColor : [ bgColor, ]));
  return {
    ':-moz-focusring': { outline: `2px dotted ${theme.color('audioPlayer', 'mozFocusRing')}`, },
    position: 'relative',
    backgroundColor,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transitionDuration: '0.4s',
    extend: [
      parseComponentProp(
        'diameter',
        diameter,
        theme.mq,
        setDiameter
      ),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
};

PlayButtonWebView.defaultProps = {
  color: [ 'audioPlayer', 'playBtn', ],
  bgColor: [ 'audioPlayer', 'playBg', ],
  diameter: '47px',
  playIconSize: 3,
  miscStyles: null,
};

function PlayButtonWebView({
  color,
  bgColor,
  diameter,
  playIconSize,
  miscStyles,
}: PlayButtonProps) {
  const { css, theme, } = useFela({
    color,
    bgColor,
    diameter,
    miscStyles,
  });

  return (
    <div
      className={css(playButtonStyle)}
      data-test="playButtonWebView"
    >
      <IconPlay
        aria-hidden
        size={playIconSize}
        color={color}
        miscStyles={{ transform: 'translateX(10%)', }}
      />
      <VisuallyHidden>{theme.audioPlayerI18n.play}</VisuallyHidden>
    </div>
  );
}

export default PlayButtonWebView;
