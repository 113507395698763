import { useState, useEffect, } from 'react';
import useMedia from './useMedia';
import checkMobileOS, { mobileOSTypes, } from '../utils/checkMobileOS';

const rssChannels = {
  default: 'spotify',
  apple: 'apple',
  android: 'google',
};

export default function usePodcastChannelType() {
  const isMobile = useMedia({ query: { until: 's', }, });
  const [ channel, setChannel, ] = useState(rssChannels.default);

  useEffect(() => {
    if (isMobile) {
      const os = checkMobileOS();
      if (os === mobileOSTypes.android) {
        setChannel(rssChannels.android);
      }
      else if (os === mobileOSTypes.apple) {
        setChannel(rssChannels.apple);
      }
    }
    else {
      setChannel(rssChannels.default);
    }
  }, [ isMobile, ]);

  return channel;
}
